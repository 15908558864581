.field-view.job {
    .view-content > h2.title {
        background: $yellow;
        color: #fff;
        text-align: center;
        padding: 1em 0;
        margin-bottom: 0;
        font-family: 'Open Sans Condensed', sans-serif;
        font-weight: 600;
        text-transform: uppercase;
        font-size: var(--card-title-size);
        line-height: 1.2em;
        &:before {
            //content: $fa-var-user-circle;
            //@include fa-icon;
            display: block;
            margin: 0 auto .1em;
            font-size: 2.5em;
        }
    }
    .view-content > .entity {
        background: #f8f8f8;
        padding: 0;
        transition: all .3s ease-in-out;
        .section-in {
                max-width: 1360px;
                padding: 0 var(--side-padding);
                    margin: 0 auto;
        }
        &.open {
            background: #fff;
        }
    }

    sm &,
    .md &,
    .lg &,
    .xl & {
        .job-section-short {
            display: flex;
            align-items: center;
            .section-text {
                flex: 0 0 50%;
                padding: 40px var(--side-padding) 40px var(--side-padding);
                display: inline-block;
                & > div {
                    max-width: calc(var(--max-width)/2 - var(--side-padding));
                    display: inline-block;
                }
            }
            .section-handle {
                flex: 0 0 50%;
                align-self: stretch;
            }
        }
            
        .entity.odd {
            .job-section-short {
                .section-text {
                    order: 1;
                }
                .section-handle {
                    order: -1;
                }
            }
        }
        .entity.even {
            .job-section-short {
                .section-text {
                    order: -1;
                    & > div {
                        float: right;
                    }
                }
                .section-handle {
                    order: 1;
                }
            }
        }
    }
        
    .xss &,
    .xs & {
        .entity {
            padding: 20px var(--side-padding);
            .section-in {
                padding: 20px 0;
            }
        }
        .section-handle > div {
            display: inline-block;
            margin: 0 auto;
            .job-handle {
            }
        }
    }
    .sm &,
    .md &,
    .lg &,
    .xl &{
        .section-handle {
            background: url(/img/pages/cariere/th-30-c.jpg) center center no-repeat;
            background-size: cover;
            position: relative;
            .job-handle {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
        
    .section-handle {
        .job-handle {
            display: block;
            cursor: pointer;
            background: $yellow;
            color: #fff;
            padding: 1em;
            border-radius: 8px;
            box-shadow: 1px 1px 4px rgba(#000, .2);
            text-align: center;
            transition: all .3s ease-in-out;
            p {
                margin: 0;
            }
            &:before {
                //content: $fa-var-plus-square-o;
                //@include fa-icon;
                font-size: 3em;
                display: block;
                margin: 0 auto;
            }
            &:hover {
                color: $yellow;
                background: #fff;
            }
        }
    }
    .job-section-more {
        background: #fff;
        max-height: 0;
        overflow: hidden;
        transition: all .5s ease-in-out;
    }
    .entity.open {
        .job-section-more {
            max-height: 1600px;
        }
    }
    .job-title-icon {
        color: #fff;
        background: $yellow;
        
        border-radius: 50%;
        display: block;
    }
    .entity h2 {
        position: relative;
        padding-top: .5em;
        font-family: 'Open Sans Condensed', sans-serif;
        font-weight: 600;
        text-transform: uppercase;
        font-size: var(--card-title-size);
        line-height: 1.2em;
    }
    .field.text,
    h3 {
        font-size: var(--text-big);
        line-height: 1.2em;
        font-weight: 300;
        color: $yellow;
    }
    .field.mission ul li{
        position: relative;
        padding: .5em 0 .5em 30px;
        svg {
            //content: $fa-var-thumbs-up;
            //@include fa-icon;
            color: $yellow;
            margin-right: 1em;
            display: block;
            position: absolute;
            top: 10px;
            left: 0;
        }
    }
    .field.footer {
        p {
            display: inline-block;
            //background: #f8f8f8;
            border: 1px #ccc solid;
            border-radius: 4px;
            padding: .2em 1em;
            a svg {
                margin-left: .5em;
                color: #ddd;
                position: relative;
                top: 4px;
            }
            a:hover {
                svg {
                    color: $yellow;
                }
            }
        }
    }
}