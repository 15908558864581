/*.field-block {
    transform-style: preserve-3d;
    & > .bg {
        transform-origin: 0 0;
        transform: translateZ(-2px) scale(3);
    }
}*/
.region-content .prop-container {
    position: relative;
    div.lazy {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
    }
}
.region-content div.bg-prop {
    position: relative;
    & > .content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.region-content .field-block.block-fullfixed {
    overflow:hidden;
    & > .bg {
        .lg &, .xl & {
            background-attachment: fixed;
        }
        
    }
    /*& > .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        
    }*/
}
.region-content .field-block {
    & > .block-inner > .block-content {
        position: relative;
    }
}

.region-content .field-block.block-proportional {
    & > .block-inner > .block-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.region-content .field-block {
    position: relative;
    & > .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-size: cover;
    }
}
.region-in > .field-block > .block-inner {
        max-width: calc(var(--max-width) + 2*var(--side-padding));
        padding: 0 var(--side-padding);
        margin: 0 auto;
    
}
.field-block.vertpad {
    padding-top: 40px;
    padding-bottom: 40px;
}
.field-block.leftcol {
    & > .block-inner > .block-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}
.region-content .field-block.horizontal > .block-inner > .block-content {
    display: flex;
    //align-items: flex-start;
    //flex-wrap: nowrap;
    & > * {
        flex: 1 1 10px;
    }
    & > * {
        margin: 0 30px;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}
.region-content .home-section {
    padding-top: 40px;
    padding-bottom: 80px;
}
.region-content .section-title {
    font-family: $opensans;
    padding-top: 30px;
    margin-bottom: 10px;
    border-bottom: 1px #eee solid;
}

.region-content .field-block.principles {
    padding: 40px 0 0;
    & > .block-inner {
        max-width: calc(var(--max-width) + 2*var(--side-padding));
        padding: 0 var(--side-padding);
        margin: 0 auto;
    }
    .block-content {
        display: flex;
        flex-wrap: wrap; 
        justify-content: center;
        .field-card {
            margin: 0;
            padding: 0;
        }
        .lg &,
        .xl & {
            .field-card {flex: 0 0 100%;}
            .field-icon {flex: 0 0 25%;}
            //grid: "[row1-start] auto [row1-end] / 1fr 1fr 1fr 1fr";
            //grid: [row1-start] " top top top top" auto [row1-end] [row2-start] " p1 p2 p3 p4" auto [row2-end] [row3-start] " f f f f" auto [row3-end]  / 1fr 1fr 1fr 1fr;
        }
        .sm &,
        .md & {
            .field-card {flex: 0 0 100%;}
            .field-icon {flex: 0 0 50%;}
            //grid: [row1-start] " top top" auto [row1-end] [row2-start] " p1 p2" auto [row2-end] [row3-start] " p3 p4" auto [row3-end] [row4-start] " f f" auto [row4-end]  / 1fr 1fr;
        }
        .xss &,
        .xs & {
            .field-card {flex: 0 0 100%;}
            .field-icon {flex: 0 0 100%;}
            //grid: [row1-start] " top" auto [row1-end] [row2-start] " p1" auto [row2-end] [row3-start] " p2" auto [row3-end] [row4-start] " p3" auto [row4-end] [row5-start] " p4" auto [row5-end] [row6-start] " f" auto [row6-end]  / auto;
        }
    }
}
/*360bus*/
.region-content .field-card.noimage.description.bus360 {
    margin-top: 60px;
    .field-html {
        font-size: 20px;
        h2 {
            letter-spacing: -1px;
        }
    }
}

.region-content .field-block.bus360-adv {
    padding: 40px 0 40px;
    background: #ebebeb;
    background: #f7f7f7;
    & > .block-inner {
        max-width: calc(var(--max-width) + 2*var(--side-padding));
        padding: 0 var(--side-padding);
        margin: 0 auto;
    }
    .block-content {
        display: flex;
        flex-wrap: wrap; 
        justify-content: center;
        .field-card {
            margin: 0;
            padding: 0;
        }
        .field-icon {
            flex: 0 0 33%;
            padding: 40px 10px;
            //border-top: 1px #f6b70f solid;
            .field-html {
                p {
                    line-height: 1.5em;
                    margin-top: 10px;
                }
            }
            .xss &, .xs &, .md & {
                border-top: none;
                .field-html {
                    p {
                        font-size: 16px;
                    }
                }
            }
            img {
                width: 10%;
                width: 20%;
                .xss &, .xs &, .md &  {
                    width: 40%;
                }
            }
        }
        .lg &,
        .xl & {
            .field-card {flex: 0 0 100%;}
            .field-icon {flex: 0 0 25%;}
            .field-icon {flex: 0 0 33%;}            
            //grid: "[row1-start] auto [row1-end] / 1fr 1fr 1fr 1fr";
            //grid: [row1-start] " top top top top" auto [row1-end] [row2-start] " p1 p2 p3 p4" auto [row2-end] [row3-start] " f f f f" auto [row3-end]  / 1fr 1fr 1fr 1fr;
        }
        .sm &,
        .md & {
            .field-card {flex: 0 0 100%;}
            .field-icon {flex: 0 0 50%;}
            //grid: [row1-start] " top top" auto [row1-end] [row2-start] " p1 p2" auto [row2-end] [row3-start] " p3 p4" auto [row3-end] [row4-start] " f f" auto [row4-end]  / 1fr 1fr;
        }
        .xss &,
        .xs & {
            .field-card {flex: 0 0 100%;}
            .field-icon {flex: 0 0 100%;}
            //grid: [row1-start] " top" auto [row1-end] [row2-start] " p1" auto [row2-end] [row3-start] " p2" auto [row3-end] [row4-start] " p3" auto [row4-end] [row5-start] " p4" auto [row5-end] [row6-start] " f" auto [row6-end]  / auto;
        }
    }
}

