#modal-container .modal-gallery {
    position: fixed;
    top: var(--header-height);
    //bottom: 0;
    left: 0;
    //right: 0;
    width: 100%;
    height: calc(100vh - var(--header-height));
    background: #222;
            &.modal-appear,
            &.modal-enter {
                    opacity: 0;
                    transform: scale(.2);
                &.modal-appear-active,
                &.modal-enter-active {
                        opacity: 1;
                        transform: scale(1);
                        transition: all .45s;
                }
            }
            &.modal-exit {
                    opacity: 1;
                    transform: scale(1);
                &.modal-exit-active {
                        opacity: 0;
                        transform: scale(.2);
                        transition: all .45s;
                }
           }


    .close {
        position: absolute; 
        top: 10px; 
        right: 10px;
        z-index: 100;
        cursor: pointer;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        background: rgba(#fff, .4);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .3s ease-in-out;
        &:hover {
            color: $yellow;
            background: #fff;
        }
    }
    .modal-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px;
        & > .field {
            flex: 1 0 100px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
.field-image {
    position: relative;
    overflow: hidden;
    & > .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        div.lazy {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            .lg &, .xl & {
                background-attachment: fixed;
            }
        }
    }
    /*& > .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        div {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
        }
    }*/
    .field.text {
        max-width: calc($max-width + var(--side-padding));
        margin: 0 auto;
        padding: 40px 100px 0;
        position: relative;
        text-align: center;
        .text {
            h2 {
                font-size: var(--card-title-size);
                line-height: 1em;
            }
            font-size: 32px;
            line-height: 1.2em;
            font-weight: 300;
        }
    }
    .gallery {
        position:relative;
        transition: transform .3s ease-in-out;
        &.mode-gallery {
                transform: translate3d(0, 0, 0)
        }
        &.mode-carousel {
                transform: translate3d(-100vw, 0, 0)
        }
        .gallery-carousel {
            z-index: 1000;
            position: absolute;
            top: 0;
            left: 100vw;
            width: 100vw;
            height: calc(100vh - var(--header-height));
            transform: translate3d(0, calc((var(--scroll) - var(--scroll-gallery)) * 1px + var(--header-height)), 0);
            background: #222;
            .carousel {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                overflow: hidden;
                .carousel-track {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    max-width: 1280px;
                    transform: translate(-50%, -50%);
                }
            }
            .pager-controls {position: absolute;width:100%;height:100%}
        }
        .back-to-gallery{
            position: absolute;
            top: 10px;
            right: 10px;
            width: 48px;
            height: 48px;
            line-height: 48px;
            border-radius: 50%;
            text-align: center;
            background: rgba(#fff, .4);
            z-index: 1000;
            cursor: pointer;
            transition: all .2s ease-in-out;
            &:hover {
                    background: #fff;
                    color: #000;
            }
        }
    }
    .gallery-gallery {
        padding: 0px;
        .img {
            position: relative;
            cursor: pointer;
            &:after {
                    //content: $fa-var-photo;
                    //@include fa-icon;
                    font-size: 48px;
                    display: block;
                    margin: 0;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: rgba(#fff, .8);
                    opacity: 0;
                    transition: opacity .3s ease-in-out;
            }
            &:hover {
                    &:after {
                            opacity: 1;
                    }
            }
        }
    }
                
        .gallery-wrap {
                max-width: 1360px;
                margin: 0 auto;
                padding: 0 80px;
        }
    .gallery-gallery {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                .img {
                        flex: 1 0 180px;
                        margin: 20px;
                        img {
                                display: block;
                                margin: 0;
                                }
                }
        }
        .pager-control {
                display: block;
                position: absolute;
                top: 50%;
                font-size: 64px;
                cursor: pointer;
                color: #fff;
                opacity: .7;
                text-shadow: 1px 1px 1px rgba(#000, .3);
                transition: all .3s ease-in-out;
                &:hover {
                        opacity: 1;
                }
                &.prev {
                        left: 15px;
                }

                &.next {
                        right: 15px;
                }
        }
                
        &:hover {
                .img-edit {
                        opacity: 1;
                }
        }
}