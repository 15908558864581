:root {
    --welcome-slogan: 24px;
    --welcome-slogan-text: 14px;
    --welcome-teaser: 14px;
}
.xss {
    --welcome-slogan: 24px;
    --welcome-slogan-text: 16px;
    --welcome-teaser: 24px;
}
.xs {
    --welcome-slogan: 24px;
    --welcome-slogan-text: 16px;
    --welcome-teaser: 24px;
}
.sm {
    --welcome-slogan: 36px;
    --welcome-slogan-text: 16px;
    --welcome-teaser: 24px;
}
.md {
    --welcome-slogan: 36px;
    --welcome-slogan-text: 16px;
    --welcome-teaser: 24px;
}
.lg {
    --welcome-slogan: 42px;
    --welcome-slogan-text: 17px;
    --welcome-teaser: 28px;
}
.xl {
    --welcome-slogan: 42px;
    --welcome-slogan-text: 17px;
    --welcome-teaser: 28px;
}

.field-welcome {
    position: relative;
    height: calc(100vh - var(--header-height));
    width: 100%;
    overflow: hidden;
    .xss &,
    .xs &,
    .sm & {
        height: auto;
    }

    & > .bg,
    & > .bg > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .xss &,
    .xs &,
    .sm & {
        /*& > .content {
            position: static;
        }*/
        .field-left, .field-right {
            position: relative;
            top: 0;
            left: 0;
            bottom: auto;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            overflow: hidden;
        }
        .field-right {display: block;}
        .field-left {
            padding-bottom: 150%;
            .field-html {
                max-width: calc(var(--max-width) + 2*var(--side-padding));
                width: 100%;
                padding: 0 var(--side-padding);
                margin: 0 auto;
            }
        }
    }
    .sm & {
        .field-left {
            padding-bottom: 50%;
            
        }
    }

    & > .field-left {
        position: absolute;
        top: 0;
        left: 0;
        width: 40%;
        height: 100%;
        .field-html {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: all 1s ease-in-out;
            font-size: var(--welcome-slogan-text);
            text-shadow: 2px 2px 10px #fff;
            line-height: 1.5em;
            h1 {
                font-size: var(--welcome-slogan);
                line-height: 1.3em;
            }
            &.active {
                opacity: 1;
            }
        }
    }
    & > .field-right {
        position: absolute;
        top: 0;
        right: 0;
        width: 60%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        & > .field-in {
            padding: 80px;
            .xss &,
            .xs &,
            .sm & {
                padding: 40px;
            }
            & > .wrap {
            position: relative;
            transform-origin: 50% 50%;
            transform: rotate(-45deg);
            display: flex;
            flex-wrap: wrap;
            //width: 100%;
            }
        }
        .field-teaser {
            flex: 0 0 50%;
            position: relative;
            transition: all .3s ease-out;
            padding: 15px;
            border: 1px #ddd dashed;
            background: rgba(#fff, .1);
            .xss &,
            .xs &,
            .sm & {
                padding: 5px;
            }
            
            &:nth-of-type(1) {
                transform: translate3d(-30px, -8px, 0);
                .content .content-in:before {
                        transform: translate3d(100%, 100%, 0);
                }
            }
            &:nth-of-type(2) {
                    transform: translate3d(-15px, -8px, 0);
                    .content .content-in:before {
                            transform: translate3d(-100%, 100%, 0);
                    }
            }
            &:nth-of-type(3) {
                    transform: translate3d(15px, 7px, 0);
                    .content .content-in:before {
                            transform: translate3d(100%, -100%, 0);
                    }
            }
            &:nth-of-type(4) {
                    transform: translate3d(30px, 7px, 0);
                    .content .content-in:before {
                            transform: translate3d(-100%, -100%, 0);
                    }
            }
            & > .field-in {
                position: relative;
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                overflow: hidden;
                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    z-index: 0;
                    background: rgba($yellow, .5);
                    transition: all .35s ease-in-out;

                }
            }
            .field.thumb {
                transition: all .3s ease-out;
                overflow: hidden;
                &:after {
                    display: block;
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 100%;
                    height: 100%;
                    background: rgba(#000, .3);
                    border-radius: 50%;
                    opacity:0;
                    transform: translate3d(-50%, -50%, 0) scale(.01);
                }
            }
            .field.thumb img {
                display: block;
                margin: 0;
                transform:  translateZ(0) rotate(45deg) scale(1.45);
                transition: all .3s ease-out;
            }
            .field.link {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform-origin: 50% 50%;
                transform: rotate(45deg) scale(1.45); // translate(-50%, -50%) ;
                display: flex;
                justify-content: center;
                align-items: center;
                .field.title {
                    color: $yellow;
                    font-size: var(--welcome-teaser);
                    line-height: 1.3em;
                    text-align: center;
                    text-transform: uppercase;
                    font-family: $opensanscond;
                    font-weight: 700;
                    text-shadow: 0 0 8px rgba(#fff, .8);

                    opacity: 0;
                    transform: translate3d(0%, 10%, -1000px) scale(2);
                }
            }
            
            &:hover {
                & > .field-in:before {
                    transform: translate3d(0, 0, 0);
                    transition: all .35s ease-in-out .2s;
                }
                .field.thumb {
                    border: 4px #fff solid;
                    transform: scale(.95);
                   &:after {
                        opacity: 1;
                        transform: translate3d(-50%, -50%, 0) scale(2);
                        transition: all .5s ease-in-out;
                    }
                }
                .field.thumb img {
                    //transform: translate3d(0, 0, 0) rotate(0) scale(.95);
                    transform:  translateZ(0) rotate(45deg) scale(1.85);
                }
                .field.link .field.title {
                    opacity: 1;
                    transform: translate3d(0, 0, -1000px) scale(1);
                    transition: all .35s ease-in-out .35s;
                }
            }
        }
    }
}