.dialog-root {
    font-family: 'Roboto', sans-serif;
}
a {
    color: $color-link;
    &:hover {
        color: $color-link-hover;
    }
}
h2 {
    font-family: 'Open Sans Condensed', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    font-size: var(--card-title-size);
    line-height: 1.2em;
}
@mixin clearfix {
  &:after {
    content: " ";
    display: table;
    clear: both;
  }
}
.clearfix {
        @include clearfix;
}
@mixin pagewide {
    margin: 0 auto;
    max-width: calc(var(--max-width) + 2*var(--side-padding));
    padding-left: var(--side-padding);
    padding-right: var(--side-padding);
}
@mixin fullwidth {
        margin-left: calc(50% - 50vw);
        margin-right: calc(50% - 50vw);
}

a.button {
        cursor: pointer;
}
.button {
    display: inline-block;
        background: $color-button;
        color: #fff;
        padding: 0.5em 1.5em;
        border-radius: 4px;
        transition: all .4s ease;
        &:hover {
                background: $color-button-hover;
                color: #fff;
                &:after {
                        padding-left:1em;
                        max-width: 200px;
                }
        }
        /*&:after {
                content: $fa-var-long-arrow-right;
                @include fa-icon;
                box-sizing: border-box;
                padding-left: 0;
                max-width:0;
                overflow: hidden;
                transition: all .4s ease;
        }*/

}
h3 {
   font-size: 1.2em;
   line-height: 1.4em;
   margin: 0.5em 0;
}