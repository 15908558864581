input[type="date"]:placeholder-shown {
    color: rgba(#000, .5);
}
.entity-edit-extra {
    display: flex;
    .entity-edit-extra-index {
        /*flex: 0 1 300px;*/
        padding: 20px 20px 20px 0;

        & > div {
            height: 60vh;
            overflow-y: auto;
        }
    }
    .entity-edit-extra-content {
        flex: 3 1 400px;
        padding-left: 20px;
        height: 60vh;
        overflow-y: auto;
    }
}
.collapse-nested {
    padding-left: 20px;
}
/*
.lg .dialog-root.dialog-wide .dialog-paper, .xl .dialog-root.dialog-wide .dialog-paper {
    width: 95vw;
    max-width: 800vw;
}*/
.color-picker {
    width: 170px;
    overflow: hidden;
    .selected-color {
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
        div {
            font-size: 18px;
            color: #000;
        }
    }
    .palette {
        padding: 10px;
        display: grid;
        grid-template-columns: repeat(5, 22px);
        grid-gap: 10px;
        div {
            width: 22px;
            height: 22px;
            border-radius: 4px;
            cursor: pointer;
        }
    }
    .editor {
        padding: 10px;
    }
}
.wysiwyg-wrapper {
    position: relative;
    border: 1px #eee solid;
}
.wysiwyg-toolbar {
    position: sticky;
    top: 0;
    z-index: 10;
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    .group {
        flex: 1 0 auto;
        border-left: 1px solid #eee;
        border-bottom: 1px solid #eee;
        height: 48px;
        margin: 0 0 -1px -1px;
        .button-content {
            display: block;
            height: 24px;
            width: 24px;
            text-align: center;
            font-size: 16px;
            line-height: 24px;
            font-weight: bold;
            sup, sub {
                position: relative;
                font-size: smaller;
            }
            sup {
                top: -6px;
            }
            sub {
                top: 6px;
            }
        }
    }

}
.wysiwyg-editor {
    position: relative;
    padding: 10px;
    overflow-y: auto;
    & > div {
        min-height: 120px;
    }
}

    .img-upload {
        padding: 20px;
        .dropzone {
            width: 160px;
            height: 160px;
            border: 2px #eee dashed;
            border-radius: 2px;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            color: #ddd;
            cursor: pointer;
            margin: 0 auto;
            div {
                display: inline-block;
            }
        }
        .buttonbar {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
        }
    }
.dialog-entity-content.type-node.bundle-news {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-columns: 8.33%;

    .field-wrap-thumb {
        grid-column: 2 / 4;
        grid-row: 1;
    }
    .group-1-0 {
        grid-column: 4 / 13;
        grid-row: 1;
    }
    .field-wrap-section {
        grid-column: 1 / 5;
        grid-row: 2;
    }
    .field-wrap-domain {
        grid-column: 5 / 9;
        grid-row: 2;
    }
    .field-wrap-business {
        grid-column: 9 / 13;
        grid-row: 2;
    }
    .field-section-editor, .field-domain-editor, .field-business-editor {
        display: flex;
        align-items: center;
    }
    .field-wrap-body {
        grid-column: 1 / 13;
        grid-row: 3;
    }
    .group-5-0 {
        grid-column: 1 / 5;
        grid-row: 4;
    }
    .field-wrap-gallery {
        grid-column: 5 / 13;
        grid-row: 4;
    }
}
