.region-content .field-card {
    position: relative;
    
    &.big .field-in {
        padding: 20px 0;
        .field-html {
            font-size: var(--text-big);
            line-height: 1.3em;
            font-weight: 300;
            text-align: justify;
        }
    }
    &.noimage {
        margin: 0 auto;
        max-width: calc(var(--max-width) + 2*var(--side-padding));
        padding: var(--node-padding);
        text-align: left;    
        p {
            margin-top: 1em;
        }
    }
    &.with-image.notext {
        max-width: calc(var(--max-width) + 2*var(--side-padding));
        padding: 0 var(--side-padding);
        margin: 0 auto;
        .field-in .field-img,
        .field-in .field-video,
        .field-in .field-vid {
            flex: 0 0 100%;
        }
    }
    &.with-image .field-in {
        display: flex;
        .xss &,
        .xs &,
        .sm & {
            flex-direction: column;
        }
        .field-video, .field-img, .field-vid, .text-content {
            flex: 0 0 50%;
        }
        .text-content {
            display: flex;
            align-items: center;
            padding: 40px var(--side-padding) 40px var(--side-padding);
            position: relative;
            font-size: var(--text-size);
            line-height: 1.4em;
            font-weight: 300;
            //opacity: 0;
            min-height: 20px;
            //transform: scale(0.8) translate3d(0, 20px, 0);
            transition: all 0.3s ease-in-out 0.5s;
            .text-wrap {
                max-width: calc(var(--max-width)/2 - var(--side-padding));
                
            }
        }
    }
    &.with-image.right {
        .md &,
        .lg &,
        .xl & {
            .field-img, .field-video, .field-vid { order: 1;}
            .text-content { order: -1; justify-content:flex-end;}
        }
    }
    .field-vid {
        display: flex;
        align-items: center;
        video {
            width: 100%;
        }
        video[poster] {
            object-fit: cover;
        }
    }
    .field-fa-icon {
        color: #f8b100;
        display: inline-block;
        font-size: 24px;
        position: relative;
        margin: 0 0 20px 0;
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px rgba(248, 177, 0, .5) solid;
        border-radius: 50%;
    }
    h2 {
        font-family: $opensanscond;
        font-weight: 600;
        text-transform: uppercase;
        font-size: var(--card-title-size);
        line-height: 1.2em;
    }
    .field-html p {
        text-align: justify;
    }    
}
