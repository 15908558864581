body {
  margin: 0;
  padding: 0;
  font-family: $opensans;
  font-size: 75%; // 12
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  line-height: 1.5em;
  color: $color-text;
}
@media screen and (min-width: 600px) {
    body {
        font-size: 87.5%; //(14)
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        line-height: 1.5em;
    }
}
@media screen and (min-width: 900px) {
    body {
        font-size: 100%; // 16
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        line-height: 1.5em;
    }
}
@media screen and (min-width: 1200px) {
    body {
        font-size: 106.3%; // 17
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        line-height: 1.5em;
    }
}
header, div, footer, h1, h3, p, section {
        box-sizing:border-box;
}
a {
        color: $color-link;
        text-decoration:none;
        &:hover {
                color: $color-link-hover;
        }
}
.button {
        cursor: pointer;
}
strong {
        font-weight: bold;
}
em {
        font-style: italic;
}
img {
        width:auto;
        max-width:100%;
        max-height:100%;
        height:auto;
        margin:0;
        display:inline-block;
}
h1 {
        font-size: 32px;
        line-height:1.6em;
        margin: 1em 0 0.5em;
}
h2 {
        font-size: 24px;
        line-height:1.6em;
        margin: 0 0 0.5em;
}
h3 {
        font-size: 1.2em;
        line-height:1.4em;
        margin: 0.5em 0;
}
p {
        margin: 0 0 1em;
}
@mixin fluid {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        //overflow: hidden;
        max-width: 100%;
        .fluid-content {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                span,
                iframe {
                        width: 100% !important;
                        height: 100% !important;
                }
        }
}
.fluid {
        @include fluid;
}
@mixin clearfix {
  &:after {
    content: " ";
    display: table;
    clear: both;
  }
}
.clearfix {
        @include clearfix;
}
@keyframes slow-bounce {
        0% {
                transform: translateY(-5%);
        }
        50% {
                transform: translateY(5%);
        }
        100% {
                transform: translateY(-5%);
        }
}
.black {
        color: #000;
}
 
.lazy {
        overflow:hidden;
        /*filter: blur(5px);
        &.lazy-loaded {
                //transition: filter .2s ease-in-out;
            filter: blur(0);
        }
        &.lazy-cache-loaded {
            filter: blur(0);
        }*/
}

@media screen and (min-device-width: 1024px) {
.lazy {
        overflow:hidden;
 /*       filter: blur(5px);
        &.lazy-loaded {
                transition: filter .2s ease-in-out;
            filter: blur(0);
        }
        &.lazy-cache-loaded {
            filter: blur(0);
        }*/
}
}
.snackbar {
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 100%);
        padding: 1em 3em;
        background: #000;
        color: #fff;
        z-index: 2000;
        transition: all .3s ease-in-out;
        &.visible {
                transform: translate(-50%, 0);
                transition: all .3s ease-in-out .3s;
        }
}
.align-right {
        text-align: right;
}
.align-left {
        text-align: left;
}
.align-center {
        text-align: center;
}
.align-justify {
        text-align: justify;
}
 
