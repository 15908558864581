.prop-container > img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.inset {
    padding-left: 40px;
}

.field-icon {
    padding: 25px;
    text-align: center;
    img {
        display: block;
        width: 30%;
        margin: 0 auto;
    }
    h2 {
        font-size: var(--icon-title-size);
        line-height: 1.2em;
        font-family: $opensans;
        font-weight: 600;
        text-transform: uppercase;
    }
    &.certificat {
        img {
            width: 100%;
        }
    }
}
.field-img img,
.field-image img {
    display: block;
    margin: 0;
    width: 100%;
}
