:root {
	--header-height: 48px;
}
.xs {
	--header-height: 32px;
}
.sm {
	--header-height: 36px;
}
.md {
	--header-height: 48px;
}
.lg, .xl {
	--header-height: 76px;
}

#header {
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: var(--header-height);
    z-index: 50;
    box-sizing: border-box;
    padding: 0;
    transition: all .4s ease;
    @include clearfix;
    background: rgba(#fff, .98);
    box-shadow: 0 0 10px rgba(#000, .2);
    animation: fadeIn 2s;

	.header-in {
       float: right;
        position: relative;
        width: 80%;
        height: 100%;
        background: $gray;
        padding-right: 40px;
        line-height: var(--header-height);
        text-align: right;
        .xss &,
        .xs & {
            width: 70%;
        }
        &:before {
            content: " ";
            display: block;
            position: absolute;
            top:0;
            left: 0;
            border-left: var(--header-height) #fff solid;
            border-bottom: var(--header-height) $gray solid;
        }
 	}
}

.logo {
        display: block;
        position: absolute;
        top: 10%;
        left: 10%;
        height: 80%;
        max-width: 160px;
        transform: translate(-50%, 0);
}
.xss .logo,
.xs .logo {
    left: 20px;
    top: 50%;
    height: 24px;
    transform: translate(0, -50%);
}

.lg,
.xl {
    #mobile-menu {
        display:none;
    }
    .main-menu {
        li {
            display:inline-block;
        }
    }
    .main-menu {
        list-style: none;
        display: inline-block;
        li {
            list-style-type:none;
                margin: 0;
                line-height: var(--header-height);
                a {
                        cursor: pointer;
                        display: inline-block;
                        font-family: $opensanscond;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 1.5em;
                        padding: .5em .5em;
                        text-decoration: none;
                        color: $menu-color;
                        text-align: center;
                        text-transform: uppercase;
                        transition: all .4s ease-in-out;
                        border-radius: 4px;
                        i {
                                text-transform: none;
                                font-style: italic;
                        }
                        &.active,
                        &:hover {
                                color: $menu-color-hover;
                                text-shadow: 1px 1px 0 rgba(#fff, .2);
                        }
                }
                &.active {
                        a {
                                background: $yellow;
                                color: #fff;
                                &:hover {
                                        color: #fff;
                                }
                        }
                }
        }
    }
}
#mobile-menu {
    position: relative;
    z-index:21;
    cursor: pointer;
    display:block;
    color: #f1f1f1;
    background: none;
    border: 0;
    float:right;
    //font-size: 20px;
    padding: 0 1em;
    height: var(--header-height);
    display: flex;
    align-items: center;
    justify-items: center;
    svg {
        display: block;
        margin: 0;
    }
}

.xss,
.xs,
.sm,
.md {
    #mobile-menu:hover {
        background: $menu-color-hover;
        color: #fff;
    }
    #mobile-menu.open {
        background: $menu-color-hover;
        color: #fff;
    }
    .main-menu {
        position: fixed;
        width:100vw;
        left:0;
        top: var(--header-height);
        transition: all .5s ease-in-out;
        z-index: 0;
        text-align: center;
        background: $gray;
        overflow: hidden;
        max-height:0;
        z-index: 100;
        li {
            display: block;
            border-top: 1px lighten($gray, 10%) solid;
            a {
                display: block;
                padding: .8em 1em;
            }
        }
        &.open {
            //background: rgba($menu-color-hover, 0.8);
            //max-height: 500px;
            max-height: calc(100vh - var(--header-height));
            overflow-y: auto;
            li a {
                display: block;
                color:#f1f1f1;

                &:hover {
                    //background:rgba($menu-color-hover, 0.98);
                    color: #fff;
                }
            }
            transform: translateY(0);
        }
    }
}
/*
.lang-menu {
        display: inline-block;
        //float:right;
        list-style: none;
        margin-left: 1em;
        li {
                list-style-type:none;
                display:inline-block;
                margin: 0;
                height: var(--header-height);
                line-height: var(--header-height);
                vertical-align: middle;
        }
        a {
                display: inline-block;
                //vertical-align: middle;
                box-sizing: border-box;
                cursor: pointer;
                width: 1.5em;
                height: 1.5em;
                line-height: 1.5em;
                font-weight: 300;
                text-align: center;
                text-transform: uppercase;
                color: #fff;
                &.active,
                &:hover {
                        //box-shadow: 0 0 8px #fff;
                        //background: $blue;
                        //color: #fff
                        border-bottom: 1px $yellow solid;
                }
        }
}
.lang-full .lang-dropdown li a.active {
        color: $orange;
}

*/