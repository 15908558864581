.header-admin-menu button svg {
    fill: #fff;
}
#field-admin-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
body {
    width: 100%;
    overflow-x: hidden;
    
}