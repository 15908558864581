.pager {
    position: relative;
    display: flex;
    color: rgba(#000, .5);
    .pager-in {
        flex-grow: 1;
        position: relative;
        display: flex;
        .xss &, .xs & {
            flex-direction: column;
        }
    }
    .arrows {
        display: flex;
        //background: #fff;
        padding: 0 10px 0 0;
    }
    .arrow {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 8px 1px;
        background: #eee;
        transition: all .3s ease-in-out;
        &:hover {
            background: $green;
            color: rgba(#fff, .8);
        }
    }
    .current-page {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 8px 1px;
        color: $green;
    }
    svg {
        flex-grow: 1;
        //width: 100%;
        max-height: 32px;
    }
    hr {
        flex-grow: 1;
        height: 0;
        border-style: solid;
        border-top: 0;
        position: relative;
        top: 35%;
    }
    .current {
        //flex: 1 0 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        .xss &, .xs & {
            flex: none;
        }
        .current-inner {
            //background: #fff;
            padding: 0 10px;
            .p {
                font-size: xx-large;
                vertical-align: text-bottom;
                font-style: italic;
                .xss &, .xs & {
                    font-size: normal;
                }
            }
        }
    }
}
.pager-footer {
    font-size: 14px;
    line-height: 1.2em;
}
