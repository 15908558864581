.field-recovery-truck-welcome {
    .slider-arrow {
        display:block;
        position:absolute;
        z-index:9;
        opacity:0;
        transition: all .5s 1.5s ease-out;
        &.c-1 {
            opacity:0;
            z-index: 11;
            .dafoe {
                padding-top: 2em;
            }
        }
        &.c-2,
        &.c-3 {
            opacity:0;
            z-index:5;
            .dafoe {
                padding-top: 2em;
            }
        }
        &.c-4 {
            top: 10%;
            right: -2%;
            opacity:0;
        }
        &.c-1, &.c-3 {
            &.active {
                        }
                        &.exit {
                        }
                }
    }       
    &[data-active="0"] .slider-arrow.c-1,
    &[data-active="2"] .slider-arrow.c-3 {
        transition-delay:0;
        opacity:1;
        transform: translate3d(0, 0, 0);
    }
    &[data-active="1"] .slider-arrow.c-1,
    &[data-active="3"] .slider-arrow.c-3 {
        transition-delay:0;
        opacity:0;
        transform: translate3d(0, 0, 0);
    }

    .slider-h.first {
        .slider-h1 {
            color: #f1f1f1;
        }
        .field.hero1 {
        }
    }
    .slider-h.second {
        .field.hero2 {
            color: #f1f1f1;
        }
        .slider-h1 {
        }
    }
    .slider-h {
        position: absolute;
        //right:50px;
        //top: 100px;
        text-align: center;
        z-index:15;
        transition: all 1s ease-in-out 3s;
        opacity: 0;
        transform:scaleY(0);
        right: 8%;
        top: 15%;
        .md &, .lg &, .xl & {
            right: 50px;
            top: 100px;
        }
        .sm & {right: 8%;top: 15%;}
        
    }
    .slider-h1 {
        color: #fff;
        font-family: $opensanscond;
        
        font-size: 72px;
        line-height:1.4em;
        font-weight: 700;
        text-shadow: 2px 2px 0 #333;
        i {
            text-transform: none;
            font-style: italic;
        }
    }
    .slider-h2 {
        color: #f3b025;
        font-size: 36px;
        line-height:1em;
        font-weight: 300;
        text-shadow: 1px 1px 0 #333, 0 0 10px #fff;
    }

    &[data-active="0"],
    &[data-active="1"],
    &[data-active="2"] {
        .slider-h.first {
            opacity: 1;
            transform:scaleY(1);
        }
    }
    &[data-active="3"],
    &[data-active="4"],
    &[data-active="5"],
    &[data-active="6"] {
        .slider-h.second {
            opacity: 1;
            transform:scaleY(1);
        }
    }
}

.field-recovery-truck-welcome {
    position: relative;
    width: 100%;
    height: calc(100vh - var(--header-height));
    overflow: hidden;
    & > .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-size: cover;
    }
    & > .content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .field.slides {
        div {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            transition: all 7s ease-out;
            opacity: 0;
            &:nth-child(1) {
                transform: scale(0.95) translate3d(-3%,-1%,0);
            }
            &:nth-child(2) {
                transform: scale(1.05) translate3d(3%,3%,0);
            }
            &:nth-child(3) {
                transform: scale(.95) translate3d(3%,0%,0);
            }
            &:nth-child(4) {
                transform: scale(1) translate3d(-8%,0%,0);
            }
            &.active {
                opacity:1;
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                    transform: scale(1) translate3d(0,0,0);
                }
            }
            &.exiting {
                transition: all 2s ease-out;
                opacity:0;
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                    transform: scale(1) translate3d(0,0,0);
                }
            }
        }
    }
}