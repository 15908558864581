.region-content .field-action {
    display: flex;
    background: $bg-action;
    color: rgba(#fff, .8);
    font-size: var(--action-size);
    line-height: 1.3em;
    padding: 0.5em 1.5em;
    margin: .5em;
    cursor: pointer;
    border-radius: 4px;
    transition: all .4s ease;
    span {
        flex: 1 0 auto;
    }
    svg {
        flex: 0 0 auto;
        display: block;
        width: 24px;
        height: 24px;
        margin-left: 1.5em;
    }
    &:hover {
        background: darken($bg-action, 10%);
        color: #fff;
    }
}
/*
.field-action {
    display: inline-block;
    &:after {
            content: $fa-var-arrow-circle-o-right;
            @include fa-icon;
            margin-left: .5em;
            color: rgba(#fff, .9);
    }
}*/