.field-recovery-call {
    text-align: center;

    .block-in {
        max-width: none;
        padding: 0;
        margin: 0;
    }
    h2.field.title {
        font-family: $opensanscond;
        font-size: var(--recovery-title);
        line-height: 1.4em;
        padding-top: 30px;
        text-align: center;
        margin: 0;
        font-weight: bold;
        text-transform: uppercase;
        display: inline-block;
        &:after {
            content: "";
            display: block;
            width: 70%;
            margin: .5em auto 1em;
            border-bottom: 3px solid #e4a327;
        }
    }
    .block-in {
        position: relative;
        //height: 0;
        //overflow: hidden;
        .xss &,
        .xs &,
        .sm &{
            height: auto;
        }
        .bg {
            /*position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;*/
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
        }
        .block-content {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
        }

        .numbers {
            position: absolute;
            font-family: $opensanscond;
            top:0;
            left:50%;
            transform: translateX(-50%);
            color: $orange;
            color: #4391d4;
            h2 {
                font-size: var(--recovery-title);
                font-weight: bold;
                //text-shadow: 1px 1px 0 darken(#4391d4, 20%);
                text-shadow: 1px 1px 0 #fff;
                opacity:0;
                transform: translateX(25px);
                transition: all .5s ease-out 1s;
                a {
                    text-decoration: none;
                    color: #4391d4;
                }
            }
            svg {
                //content: $fa-var-mobile;
                //@include fa-icon;

                width: 10vw;
                //color: $orange;
                position: absolute;
                top: 0;
                left: -7vw;
                text-shadow: 2px 2px 0 darken(#4391d4, 10%);
                transform: translate(-50%, -25%) rotate(15deg);
                opacity:0;
                transition: all .5s ease-out .8s;
            }
        }
       .field.text {
            text-align: left;
            font-size: var(--recovery-small);
            line-height: 1.3em;
            padding: 1em 2em 0;
            background: rgba(#fff, .6);
            position: absolute;
            bottom: 1em;
            left: 5%;
            width: 35%;
            border-radius: 12px;
            opacity:0;
            transform: translateX(-25px);
            transition: all .5s ease-out 1.5s;
            p {
                margin-bottom: 1em;
                strong {
                    color: #333;
                }
            }
            .xss &,
            .xs & {
                width: 80%;
                bottom: -25%;
                left: auto;
                right:10%;
                z-index: 20;
            }
        }
    }
                
    &.visible {
        .block-in {
            .numbers {
                h2 {
                    opacity: 1;
                    transform: translateX(0);
                }
                svg {
                    opacity:1;
                }
            }
            .field.text {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }
}
.xss,
.xs,
.sm {
    .block.block-RecoveryCall {
        .block-in {
            .bg {
                background-size: contain;
                background-position: top center;
            }
            .block-content {
                position: relative;
            }
            .numbers {
                display: inline-block;
                margin: 0 auto;
                position: relative;
                left: auto;
                transform: none;
                &:before {
                    left: -40px;
                }
            }
            .field.text {
                position: relative;
                bottom: 0;
                right: auto;
                width: 100%;
                margin-top: 100px;
            }
        }
    }
}
