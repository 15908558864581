.field-view.caleidoscop {
    .view-content {
        .entity {
            display: flex;
            align-items: center;
            padding: 0;
            .article-thumb {
                flex: 0 0 50%;
            }
            .article-content {
                flex: 0 0 50%;
                display: inline-block;
                padding: 40px var(--side-padding) 40px var(--side-padding);
                .text-wrap {
                    max-width: calc(var(--max-width)/2 - var(--side-padding));
                    display: inline-block;
                    font-weight: 300;
                }

                h2 {
                    font-family: $opensanscond;
                    font-weight: 600;
                    text-transform: uppercase;
                    font-size: var(--card-title-size);
                    line-height: 1.2em;
                    color: #000;
                    &:hover {
                        color: $yellow;
                    }
                }

            }
            &.odd {
                .article-thumb {
                    order: -1;
                }
                .article-content {
                    order: 1;
                }
            }
            &.even {
                .article-thumb {
                    order: 1;
                }
                .article-content {
                    order: -1;
                    .text-wrap {
                        float: right;
                    }
                }
            }
        }
    }
}