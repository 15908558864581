.field-block.recovery-contact {
    text-align: center;
    padding: 40px 0;
    .section-title {
        font-family: $opensanscond;
        font-size: var(--recovery-title);
        line-height: 1.4em;
        padding-top: 30px;
        text-align: center;
        margin: 0;
        font-weight: bold;
        color: #fff;
        text-shadow: 2px 2px 0 #333;
        text-transform: uppercase;
        display: inline-block;
        border-bottom: none;
        &:after {
            content: "";
            display: block;
            width: 70%;
            margin: .5em auto 1em;
            border-bottom: 3px solid #e4a327;
        }
    }

    .section-content > .block-inner > .block-content {
        display: flex;
        & > .details {
            flex: 1 0 35%;
        }
        & > .field-form {
            flex: 1 0 65%;
        }
    }
    .details {
        text-align: left;
        //max-width: 1360px;
        //margin: 0 auto;
        //padding: 0 80px 40px;
        //@include clearfix;
        color: #fff;
        text-align: right;
        padding-right: 40px;
        .telno a {
            color: #fff;
            font-size: var(--recovery-telno-big);
            line-height: 1.4em;
            font-family: $opensanscond;
            font-weight: bold;
            text-shadow: 1px 1px 0 #333;
            margin-bottom: .5em;
            transition: all .4s ease-in-out;
            svg {
                width: 1em;
                margin-right: .5em;
            }
        }
        a {
            color: #fff;
            &:hover {
                color: $yellow;
            }
        }
        .field-html {
                margin-top: 1em;
                text-shadow: 1px 1px 0 #333;
                h2 {
                        font-weight: bold;
                }
                p {
                        margin: 0;
                }
        }
    }
    .field-form {
        text-align: left;
    }
}