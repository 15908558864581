.node {
input[type=text], textarea {
    background: #fff;
    border: 0;
    //border-bottom: 1px rgba($gray, .2) solid;
    outline: none;
    padding: .5em;
    width: 100%;
    display: block;
    box-sizing: border-box;
    margin-bottom: 15px;
    font-family: inherit;
    font-size: inherit;
    font-size: .875em;
    &::placeholder {
        //color: rgba($gray, .3);
    }
}

textarea {
        //border: 1px rgba($gray, .2) solid;
        height: 160px;
}


input[type=text],
        textarea {
                border: 1px #ccc solid;
                padding: 1em .5em;
                &.err {
                        border-color: #f00;
                }
                &::placeholder {
                        color: rgba(#000, .3);
                }
                &:focus {
                        &::placeholder {
                                //color: rgba($yellow, .4);
                                color: rgba(#000, .1);
                        }
                }
        }
}