body {
    scroll-behavior: smooth;
}
@media screen and (min-width: 900px) {
    ::-webkit-scrollbar {
        width: 10px;
        background-color: $color-scrollbar-track;
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: $color-scrollbar-track;
    }

    ::-webkit-scrollbar-thumb {
        //background-color: rgba($red, .5);
        background-image: linear-gradient($color-scrollbar-thumb, $color-scrollbar-thumb 50%, $color-scrollbar-thumb2 50%, $color-scrollbar-thumb2);
        /*background-image: -webkit-gradient(linear, 0 0, 0 100%,
                          color-stop(.5, rgba(255, 255, 255, .2)),
                                                  color-stop(.5, transparent), to(transparent));*/
    }
}
