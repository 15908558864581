.carousel {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    transition: all 1s ease-in-out;
    & > .track
    {
        display: block;
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 0;
        &.active {
            z-index: 1;
        }
    }
    &.default-move .item {
        transform: translate(100%, 0);
    }
    &.fill-parent {
        .item .lazy {
            background-size: cover;
            background-position: center center;
        }
    }
    .arrow {
        position: absolute;
        top: 50%;
        color: #fff;
        //background: rgba(0, 0, 0, .5);
        //opacity: 0.8;
        transition: all 0.3s ease;
        z-index: 1;
        transform: translate(0, -50%);
        cursor: pointer;
        svg {
            display: block;
            margin: 0;
            width: 32px;
            .st0 {
                fill: rgba(#fff, .5);
                transition: all .4s ease-in-out;
            }
            .st1 {
                fill: #fff;
                transition: all .4s ease-in-out;
            }
        }
        &:hover {
            box-shadow: 2px 2px 16px rgba(#000, .5);
            svg {
                .st0 {
                    fill: rgba(#fff, .95);
                }
                .st1 {
                    fill: #666766;
                }
            }
        }
        &.prev {
            left: 0px;
        }
        &.next {
            right: 0px;
        }
    }

}