:root {
        --lang-height: 12px;
}
.xs {
}
.sm {
}
.md {
}
.lg {
        --lang-height: 16px;
}

.lang-selector {
    float: right;
    img {
        display: inline-block;
        height: var(--lang-height);
        margin: 0;
    }
    .lang-selector-in {
        display: inline-block;
        height: 2em;
        font-size: var(--lang-height);
        line-height: 1em;
        position: relative;
    }
    
}

.xss,
.xs,
.sm,
.md {
    .lang-selector {
        .lang-current {
            display: inline-block;
            box-sizing: border-box;
            cursor: pointer;
            font-weight: 300;
            text-align: center;
            text-transform: uppercase;
            color: #fff;
            height: calc(2 * var(--lang-height));
            line-height: calc(2 * var(--lang-height));
            padding: 0 .5em;
            //padding: calc(var(--lang-height) / 2);
            &:hover{
                background: #fff;
                color: #222;
            }
        }

        .lang-dropdown {
            display: none;
            list-style: none;
            margin: 0;
            padding: 0;
            font-size: var(--lang-height);
            line-height: 1em;
            width: 75px;
            box-shadow: 2px 2px 5px rgba(#000, .4);

            li {
                display: block;
                list-style-type: none;
                margin: 0;
                padding: 0;
                a {
                    display: block;
                    padding: .5em;
                    color: #222;
                    text-transform: uppercase;
                    &:hover {
                        background: #333;
                        color: #fff;
                    }
                }
            }
        }
                           
        &.open {
            .lang-current {
                background: #fff;
                color: #222;
            }

            .lang-dropdown {
                display: block;
                position: absolute;
                top: 100%;
                right: 0;
                background: #fff;
            }
        }
    }
}
.lg,
.xl {
    .lang-selector {
        .lang-current {
            display: none
        }
    
        .lang-dropdown {
            list-style: none;
            display: inline-block;
            li {
                list-style-type:none;
                display: inline-block;
                a {
                    display: block;
                    padding: .5em;
                    color: #fff;
                    text-transform: uppercase;
                    &.active {
                        color: $orange;
                    }
                    &:hover {
                        background: #333;
                        color: #fff;
                    }
                }
            }
        }               
    }               
}