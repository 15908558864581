:root {
        --max-width: 1200px;
        --side-padding: 15px;
        --node-padding: 0 15px 20px;
    --hero-padding: 180px 80px 120px;
    --title-font-size: 74px;
}
.xs {
        --side-padding: 30px;
        --node-padding: 0 30px 20px;
}
.sm {
        --side-padding: 40px;
        --node-padding: 0 40px 20px;
}
.md {
        --side-padding: 40px;
        --node-padding: 0 40px 0px;
}
.lg, .xl {
        --side-padding: 80px;
        --node-padding: 0 80px 0px;
    --hero-padding: 180px 80px 120px;
    --title-font-size: 74px;
}

@keyframes rotateIn {
    from {
        transform: rotateX(180deg);
    }
    to {
        transform: rotateX(0deg);
    }
}
@keyframes rotateOut {
    from {
        transform: rotateX(0deg);
    }
    to {
        transform: rotateX(180deg);
    }
}

.node {
    margin-top: var(--header-height);
}

#root {
    width: 100%;
    .xss &, .xs &, .sm & {
        overflow: hidden;
    }
}
.node {
    position: relative;
    z-index: 5;
    min-height: 100vh;
}
.node-content {
    width: 100%;
}
.node-exited {
    position: fixed;
    top: 0;
    left: -100vw;
    width: 100%;
    z-index: 6;
}
.node-entering {
    position: fixed;
    //z-index: 1;
}
.node-exiting {
    top: 0;
    left: 0;
    width: 100%;
    z-index: 6;

    .region-hero .field-hero .field.img,
    .region-hero .field-hero .hero-title-wrap,
    .region-content {
        //opacity: 0;
        animation: fadeOut 1s both;
        //animation: fadeOut 30s both;
    }
}
.node-entering {
    .region-hero .field-hero .field.img,
    .region-hero .field-hero .hero-title-wrap,
    .region-content {
        //animation: fadeIn1 1s both;
        //animation: fadeIn1 30s both;
        //opacity: 0;
    }
}
//.region-hero,
.region-content {
    min-height: 100vh;
    position: relative;
    background: #fff;
}
.node-article {
    .region-content {
        margin: 0 auto;
        max-width: calc(var(--max-width) + 2*var(--side-padding));
        padding: var(--node-padding);
        text-align: left;
        position: relative;
    }
}
