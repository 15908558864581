.region-content .field-block.home-desc {
    & > .block-inner {
        position: relative;
    }
    & > .block-inner > .block-content {
        /*left: 50%;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: flex-start;
        padding-top: 80px;
        padding-right: var(--side-padding);
        */
    max-width: calc(var(--max-width) + 2*var(--side-padding));
    padding: 0 var(--side-padding);
    margin: 0 auto;
        display: grid;
        justify-items: start;
        align-items: start;
        align-content: start;
        grid: [row1-start] " . . title title" auto [row1-end] 
        [row2-start] " . . text text" auto [row2-end] 
        [row3-start] " . . link link" auto [row3-end]  
        / 1fr 1fr 1fr 1fr;
        .xss &,
        .xs &,
        .sm & {
            grid: [row1-start] " title" auto [row1-end] 
            [row2-start] " text" auto [row2-end] 
            [row3-start] " link" auto [row3-end]  
            / 1fr;
            }
    }
    h2 {
        font-family: "Open Sans Condensed", sans-serif;
        font-size: var(--card-title-size);
        line-height: 1.2em;
        font-weight: 600;
        text-shadow: 2px 2px 10px #fff;
    }
    p {
        text-align: justify;
        font-weight: 300;
        text-shadow: 2px 2px 10px #fff;
        .xss &,
        .xs &,
        .sm & {
            //font-size: 16px;
            font-size: var(--welcome-slogan-text);
            line-height: 1.5em;
        }
    }
    .field.title {
        grid-area: title;
            padding-top: 40px;
    }
    .field.text {grid-area: text;}
    .field-action {
        margin-left: 0;
        background: $yellow;
        grid-area: link;
    }
}
.region-content .home-principles > .block-inner {
    padding: 0;
}
.region-content .home-principles > .block-inner > .block-content {
    display: grid;
    grid: 
        [row1-start] " title title title title" auto [row1-end] 
        [row2-start] " p1 p2 p3 p4" auto [row2-end]
        / 25% 25% 25% 25%;
    padding: 40px var(--side-padding);
    .sm &,
    .md & {
        grid: [row1-start] " title title" auto [row1-end] 
        [row2-start] " p1 p2" auto [row2-end] 
        [row3-start] " p3 p4" auto [row3-end]
        / 1fr 1fr;
        padding: 40px var(--side-padding);
    }
    .xss &,
    .xs & {
        grid: [row1-start] " title" auto [row1-end] 
        [row2-start] " p1" auto [row2-end] 
        [row3-start] " p2" auto [row3-end] 
        [row4-start] " p3" auto [row4-end] 
        [row5-start] " p4" auto [row5-end]  / 1fr;
        padding: 30px var(--side-padding);
    }
    .field-card {
        grid-area: title;
    }
    .field.f9 {grid-area: p1;}
    .field.f10 {grid-area: p2;}
    .field.f11 {grid-area: p3;}
    .field.f12 {grid-area: p4;}
    .field-icon {
        display: flex;
        flex-direction: column;
        img {
            flex: 0 0 64px;
            height: 64px;
        }
    }
}

/*bus360*/
.region-content .home-bus360 {
    margin-top: 100px;
    margin-bottom: 20px;
    .xss &,
    .xs & {
        margin-top: 0;
        margin-bottom: 20px;
    }
}
.region-content .home-bus360 > .block-inner {
    position: relative;
    padding-top: 80px;
    padding-bottom: 80px;
    .xss &,
    .xs & {
        padding-top: 40px;
        padding-bottom: 40px;
        }
}
.region-content .home-bus360 > .block-inner > .block-content {
    max-width: calc(var(--max-width) + 2*var(--side-padding));
    padding: 0 var(--side-padding);
    margin: 0 auto;
    display: grid;
    grid: 
        [row1-start] " . . . . img img img" auto [row1-end] 
        [row2-start] " title title title title img img img" auto [row2-end] 
        [row3-start] " text text text text img img img" auto [row3-end] 
        [row4-start] " link link link link img img img" auto [row4-end] 
        [row5-start] " . . . . img img img" auto [row5-end] 
        / 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    justify-items: start;
    align-items: center;
    justify-content: stretch;
    align-content: stretch;
    .xss &,
    .xs {
        grid: [row1-start] " title title" auto [row1-end] 
        [row2-start] " img img" auto [row2-end] 
        [row3-start] " text text" auto [row3-end]
        [row4-start] " text text" auto [row4-end]  
        [row5-start] " link link" auto [row5-end] 
        / 1fr 1fr;
    }
    .field.title {grid-area: title}
    .field.text {grid-area: text}
    .field.path {grid-area: link}
    .field.img {grid-area: img}
    .field.path {
        display: inline-block;
        background: #f8b100;
        color: #fff;
        padding: 0.5em 1.5em;
        border-radius: 4px;
        transition: all 0.4s ease;        
    }
    .field.img {
        width: 100%;
        height: 100%; 
        display: flex;
        align-items: center;
    }
    .xss &,
    .xs & {
        .field.text {
            //display: none;
        }
    }
}

.region-content .home-recovery > .block-inner {
    position: relative;
}
.region-content .home-recovery > .block-inner > .block-content {
    max-width: calc(var(--max-width) + 2*var(--side-padding));
    padding: 0 var(--side-padding);
    margin: 0 auto;
    display: grid;
    grid: 
        [row1-start] " . . . img img img img" auto [row1-end] 
        [row2-start] " title title title img img img img" auto [row2-end] 
        [row3-start] " text text text img img img img" auto [row3-end] 
        [row4-start] " link link link img img img img" auto [row4-end] 
        [row5-start] " . . . img img img img" auto [row5-end] 
        / 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    justify-items: start;
    align-items: center;
    justify-content: stretch;
    align-content: stretch;
    .xss &,
    .xs {
        grid: [row1-start] " . img" auto [row1-end] 
        [row2-start] " title img" auto [row2-end] 
        [row3-start] " link img" auto [row3-end]
        [row4-start] " . img" auto [row4-end]  
        / 1fr 1fr;
    }
    .field.title {grid-area: title}
    .field.text {grid-area: text}
    .field.path {grid-area: link}
    .field.img {grid-area: img}
    .field.path {
        display: inline-block;
        background: #f8b100;
        color: #fff;
        padding: 0.5em 1.5em;
        border-radius: 4px;
        transition: all 0.4s ease;        
    }
    .field.img {
        width: 100%;
        height: 100%; 
        display: flex;
        align-items: center;
    }
    .xss &,
    .xs & {
        .field.text {
            display: none;
        }
    }
}
.region-content .home-offer > .block-inner {
    position: relative;
}
.region-content .home-offer > .block-inner > .block-content {
    display: grid;
    grid: 
        [row1-start] " . . . . . . . . . . . ." auto [row1-end] 
        [row2-start] " title title title title title title title title title link link link" auto [row2-end] 
        [row3-start] " text text text text text text text text text link link link" auto [row3-end] 
        [row4-start] " . . . . . . . . . . . ." auto [row4-end] 
        / 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    justify-items: start;
    align-items: center;
    align-content: center;
    color: #fff;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.5em;
    max-width: calc(var(--max-width) + 2*var(--side-padding));
    padding: 0 var(--side-padding);
    margin: 0 auto;
    .xss &,
    .xs & {
        grid: [row1-start] " ." auto [row1-end] 
        [row2-start] " title" auto [row2-end] 
        [row3-start] " link" auto [row3-end] 
        [row4-start] " ." auto [row4-end]  / 1fr;

    }
    .field.title {grid-area: title;}
    .field.text {grid-area: text;}
    .field.path {grid-area: link;}
    .field.img {
        grid-area: row1-start / 1 / row4-end / 6;
        transform: translate3d(-30%, 0, 0) scale(1);
        width: 100%;
        height: 100%;
        img {
            left: 10%;
            top: 10%;
            width: 80%;
            height: 80%;
            transform: translate3d(0, 0, 0) scale(1);
            transition: all 0.3s ease-in-out;
            &:hover {
                transform: scale(1.1);
            }
        }
    }
    .field.title {
        text-transform: uppercase;
        font-size: 24px;
        line-height: 1.2em;
        padding-bottom: 20px;
    }
    .sm &,
    .md &,
    .lg &,
    .xl & {
        .field.text {
            padding-left: 180px;
        }
    }
    .xss &,
    .xs & {
        .field.text {display:none}
        .field.img {
            grid-area: row1-start / 1 / row4-end / 2 !important;
        }
    }
    .lg &,
    .xl & {
        .field.img {
            grid-area: row1-start / 1 / row4-end / 6 !important;
        }
    }

    .field.path {
        justify-self: end;
        display: inline-block;
        background: #f8b100;
        color: #fff;
        padding: 0.5em 1.5em;
        border-radius: 4px;
        transition: all 0.4s ease;
    }
}
