
.region-hero ,.region-hero .region-in, .field-hero {
    transform-style: preserve-3d;
}
.field-hero.slim {
    height: 400px;
}
.field-hero {
    & > .bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            .lg &, .xl & {
                background-attachment: fixed;
            }
            
        
    }
    .field.img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    
    .hero-title-wrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform-origin: 0 0;
    }
}
.field-hero {
    --title-h: 0;
    --screen-h: 0;
    --scroll: 0;
    position: relative;
    width: 100%;
    height: calc(100vh - var(--header-height));
    overflow: hidden;
    .field-img {
        will-change: opacity;
        opacity: calc((var(--screen-h) / 2 - var(--scroll)) / 300);
    }
    .hero-title {
        position: absolute;
        bottom: 20%;
        left: 0;
        top: auto;
        width: 100%;
        transform: none;
        will-change: transform;
        transform: translate3d(0, 
        calc(var(--title-h) * var(--scroll) / var(--screen-h) * 1px), 0);
        display: flex;
        .hero-title-in {
            flex: 0 0 auto;
            display: flex;
            flex-direction: column;
        }
        .spacer {
            flex: 1 0 1px;
        }
        p {margin: 0; padding: 0;}
        h1 {
            margin: 0;
            text-transform: uppercase;
            font-size: var(--slide-h1-big);
            line-height: 1em;
            font-weight: 400;
            color: #f8b100;
            background: rgba(255, 255, 255, .9);
            border-top: 3px #f8b100 solid;
            padding: 0.15em 5vw;  
        }
        h3 {
            font-size: calc(var(--slide-h1-big) / 3.5);
            line-height: 1em;
            color: #fff;
            background: rgba(0, 0, 0, .8);
            margin: 0;
            text-transform: uppercase;
            padding: 0.35em 5vw;
        }
    }
    &.middle .hero-title {
        position: absolute;
        bottom: auto;
        top: 50%;
        left: 50%;
        width: 80%;
        //transform: translate(-50%, -50%);
        will-change: transform;
        transform: translate3d(-50%, 
        calc(-50% + var(--title-h) * var(--scroll) / var(--screen-h) * 1px), 0);
        text-align: center;
        display: block;
        .hero-title-in {
            flex: 0 0 auto;
            display: flex;
            flex-direction: column;
        }
        .spacer {
            flex: 0 0 0;
        }
        h1, h2, h3 {
            background: none;
            border: none;
        }
        h3 {
            font-size: var(--slide-h3-big);
            background: none;
        }
    }
}
.field-hero {
    .field.img1 {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50%;
        .portrait & {
            width: 80%;
        }
    }
    .field.slogan {
        position: absolute;
        bottom: 10%;
        left: 50%;
        transform: translate( -50%, 0);
        width: 80%;
        text-align: center;
        font-size: var(--text-big);
        line-height: 1.5em;
        color: #222;
        text-shadow: 1px 1px 1px rgba(#fff, .8);
    }
}
.field-hero.about {
    .field.slogan {
        top: 10%;
    }
}