.field.rt-desc {
    & > .block-inner > .block-content {
        padding: 0 var(--side-padding);
        display: grid;
        grid: 
            [row1-start] " . . . title title" auto [row1-end] 
            [row2-start] " . . . text text" auto [row2-end] 
            [row3-start] " . . . text2 text2" auto [row3-end] 
            [row4-start] " . . . phone phone" auto [row4-end] 
            / 1fr 1fr 1fr 1fr 1fr;
        justify-items: stretch;
        align-content: center;

        .xss &,
        .xs &, 
        .sm &,
        .md.portrait & {
            /*grid: [row1-start] " title title phone phone" auto [row1-end] 
            [row2-start] " text text text text" auto [row2-end] 
            [row3-start] " text2 text2 text2 text2" auto [row3-end] 
            / 1fr 1fr 1fr 1fr;*/
            display:block;
        }

    }
    .field.title { grid-area: title;}
    .field.text { grid-area: text;}
    .field.text2 { grid-area: text2;}
    .field.phone { grid-area: phone;}
    .field.title,
    .field.text,
    .field.text2 {
        font-family: $opensans;
        color: #fff;
        text-shadow: 1px 1px 0 #333;
        h2 {
            //font-size: var(--recovery-h2);
            font-size: var(--card-title-size);
            line-height: 1.5em;
            //text-transform: none;
            //font-weight: 300;
            &:after {
                content: "";
                display: block;
                width: 30%;
                margin: 0.5em 0 1em;
                border-bottom: 2px solid #e4a327;
            }
            .xss &,
            .xs &, 
            .sm &,
            .md.portrait & {
                padding: 2em 0 0;
            }
        }
        p{
            font-size: var(--welcome-slogan-text);
            line-height: 1.4em;
        }
    }
    .field.phone {
        display: inline-block;
        //font-size: var(--recovery-telno);
        //font-size: var(--card-title-size);
        font-size: 24px;
        line-height: 2em;
        vertical-align: center;
        background: rgba(#000, .4);
        text-align:center;
        padding: 0.2em 1em;
        border-radius:8px;
        font-weight: 600;
        //opacity:0;
        color: $orange;
        //transform: scaleY(0);
        transition: all 1s ease-out 1.5s;
        p {
                margin: 0;
        }
        a {
            color: $orange;
            text-decoration: none;
            &:before {
               // content: $fa-var-mobile;
                //@include fa-icon;
                font-size: 2em;
                margin-right: .3em;
            }
        }
        i {
            color: $orange;
        }
    }

}