.field-manager {
    padding: 15px 0;
    display: flex;
    align-items: center;
    .field-type-select-form-control {
        min-width: 160px;
    }
    & > button {
        top: 12px;
    }
}

.field-admin-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
}
.field-shadow {
    position: absolute;
    z-index: 500;
    border: 1px #333 dotted;
    pointer-events: none;
    .field-admin-menu {
        pointer-events: auto;
    }
}
.field_admin_hover {
    border: 1px #333 dotted;
}
.field-admin-menu {
    position: absolute;
    top: 0;
    right: 0;
    /*.under-header & {
        top: var(--header-height);
    }*/
}
  