.field-recovery-description2 {
    .block-in {
        max-width: calc(var(--max-width) + 2*var(--side-padding));
        padding: 40px var(--side-padding);
        margin: 0 auto;        
    }
    .field.title {
        font-family: $opensanscond;
        font-size: var(--recovery-title);
        line-height: 1.4em;
        padding-top: 30px;
        text-align: center;
        margin: 0;
        font-weight: bold;
    }
    .field.subtitle {
        font-family: $opensans;
        color: #c2c2c2;
        font-weight: 300;
        text-align: center;
        font-size: 50px;
        line-height: 1.4em;
        margin: 0;
    }
    .block-content {
        position: relative;
        .field.bg {
            display:block;
            position: relative;
            float:right;
            width:80%;
            shape-outside: url('/img/recovery/masca-4.png');
            shape-margin: 2em;
            z-index:2;
        }

        .field.text {
            font-size: var(--recovery-text);
            line-height: 1.3em;
            padding: 1em 5% 1em;
            margin:0;
            padding-top: 105px;
            strong {
                color: #333;
            }
        }
        .field.text2 {
            font-size: var(--recovery-text);
            line-height: 1.3em;
            background-image: linear-gradient(to right, $orange, $yellow);
            border-radius:12px;
            color: #fff;
            font-weight:600;
            text-shadow: 1px 1px 0 darken($orange, 10%);
            padding: 1em 5% 1em;
            margin:40px 0 0;
        }
    }
                
    .field.title, .field.subtitle {
        opacity:0;
        transform: translate3d(0,-20px,0);
        transition: all 1s ease-out .5s;
    }
    .field.text {
        transform: translate3d(-100%,0,0);
        transition: all 1s ease-out .75s;
    }
    .field.text2 {
        transform: translate3d(-100%,0,0);
        transition: all 1s ease-out 1s;
    }
    .field.bg {
        opacity:0;
        transform: translate3d(20px,0,0);
        transition: all 1s ease-out 1s;
    }
    &.visible {
        .field.title, .field.subtitle {
            opacity:1;
            transform: translate3d(0,0,0);
        }
        .field.text,.field.text2 {
            transform: translate3d(0,0,0);
        }
        .field.bg {
            opacity:1;
            transform: translate3d(0,0,0);
        }
    }
}
.xss,
.xs {
    .field-recovery-description2 {
        padding-bottom: 20px;
        .block-content {
            .field.bg {
                width: 60%;
                margin-top: 50px;
                shape-margin: 1em;
            }
        }
        .field.text {
            padding-top: 20px;
        }
    }
}
        
.sm {
    .field-recovery-description2 {
        padding-bottom: 20px;
        .block-content {
            .field.bg {
                //width: 60%;
                //margin-top: 50px;
                shape-margin: 1em;
            }
        }
        .field.text {
            padding-top: 70px;
        }
    }
}
