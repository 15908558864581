.region-center .region-in.t-docList-document {
    .field-wrap {
        display: grid;
        .field-kind, .field-docs {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 2;
        }
        .docs-appear,
        .docs-enter {
            opacity: 0;
            //transform: scale3d(1, 0.2, 1);
            overflow: hidden;
            .header, .embed-paper {
                //transform: scale3d(1, 5, 1);
            }
            &.docs-appear-active,
            &.docs-enter-active {
                opacity: 1;
                //transform: scale3d(1, 1, 1);
                transition: all .3s ease-out;
                .header, .embed-paper {
                    //transform: scale3d(1, 1, 1);
                    //transition: all .3s ease-out;
                }
            }
        }
        /*
        .kind-exit {
            overflow: hidden;
            opacity: 1;
            .activated {
                position: relative;
                z-index: 1;
                transform: scale3d(1, 1, 1);
                svg {
                    transform-origin: 0  50% 0;
                    position: relative;
                    transform: scale3d(1, 1, 1);
                }
                .row-in {
                    display: none;
                }
            }
            
            &.kind-exit-active {
                opacity: 0;
                transition: all .3s ease-in;
                .activated {
                    transform: scale3d(1, 10, 1);
                    transition: all .3s ease-in;
                    svg {
                        transform: scale3d(10, 1, 1) translate3d(50%, 0, 0);
                        transition: all .3s ease-in;
                    }
                }
            }
        }
        */
    }
}
.region-center .region-in.t-document-docList {
    .field-wrap {
        display: grid;
        .field-kind, .field-docs {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 2;
        }
        /*
        .kind-appear,
        .kind-enter {
            opacity: 0;
            transform: translate3d(0, 50px, 0);
            &.kind-appear-active,
            &.kind-enter-active {
                opacity: 1;
                transform: translate3d(0, 0, 0);
                transition: all .3s ease-out;
            }
        }
        */
        .docs-exit {
            opacity: 1;
            //transform: scale3d(1, 1, 1);
            //overflow: hidden;
            .header, .embed-paper {
               // transform: scale3d(1, 1, 1);
            }
            &.docs-exit-active {
                opacity: 0;
                //transform: scale3d(1, 0.2, 1);
                transition: all .3s ease-in;
                .header, .embed-paper {
                    //transform: scale3d(1, 5, 1);
                    //transition: all .3s ease-in;
                }
           }
        }
    }
}

@mixin stagger-delay($i, $initial-delay, $stagger-delay) {
	@while $i > 0 {
		&:nth-of-type(#{$i}) {
			transition-delay: $initial-delay + $stagger-delay * $i;
		}
		$i: $i - 1;
	}
}

.region-center .region-in {
    .field-kind {
           .row {
                transform-origin: 0  0% 0;
                backface-visibility: hidden;
                transform: rotateX(180deg);
            }
            .row.mounted {
                transform: rotateX(0deg);
                transition: transform .3s ease-in-out;
                @include stagger-delay(10, 0s, 0.05s);
            }
            }
        .kind-exit {
            .row.mounted {
                transform-origin: 0  50% 0;
                backface-visibility: hidden;
                transform: rotateX(0deg);
                transition: none;
            }
            &.kind-exit-active {
                .row.mounted {
                    transform: rotateX(-180deg);
                    transition: all .3s ease-in-out;
                }
            }
        }
 }
.region-center .region-in.t-docList-docList {
    .field-wrap {
        display: grid;
        .field-kind {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 2;
        }
        //.kind-appear,
        //.kind-enter {
        //    position: relative;
        //    z-index: 1;
        //}
    }
}