.field-department {
    margin: 0 auto;
    max-width: calc(var(--max-width) + 2*var(--side-padding));
    padding: var(--node-padding);
    display: flex;
    align-items: flex-start;
    line-height: 2em;
    & > * {
        flex: 0 0 50%;
    }
    & > .name {
        background: $yellow;
        color: #fff;
        padding: .5em 2em;
        font-weight: bold;
        text-transform: uppercase;
        display: flex;
            padding-left: 1em;
            font-size: 24px;
            line-height: 48px;
        svg {
            order: -1;
            margin-right: 1em;
        }
    }
    .sub-content {
        padding: 20px 0 0 40px;
    }

    .md &,
    .lg & {
        padding: var(--node-padding);
        padding-top: 40px;
        padding-bottom: 40px;
        & > .name {
            width: 50%;
            float: left;
        }
        .sub-content {
            width: 50%;
            float: right;
        }
    }

}

.field-subdepartment {
    margin-bottom: 30px;
    h3 {
        text-transform: uppercase;
    }
    .field.head, 
    .field-text a {
        display: flex;
        svg {
            order: -1;
            flex: 0 0 48px;
            
            color: $yellow;
            //margin-right: 8px;
            position: relative;
            top: 8px;
        }
        &.head svg {
            top: 0;
        }
    }
    
    .field div {
        display: inline-block;
        padding-left: .5em;
        line-height: 34px;
    }
    .field.head {
        font-weight: bold;
    }
}