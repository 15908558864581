#footer {
    position: relative;
}
#footer {
        position:relative;
        clear: both;
        text-align:center;
        font-size: 12px;
        line-height:18px;
        background: #f9f9f9;
        z-index: 0;
        font-size: 14px;
        line-height: 1.5em;

    svg {
        margin-right: 8px;
        color: #444;
    }

        .auth {
                position: absolute;
                right: 40px;
                bottom: 6px;
                a {
                font-size: 12px;
                line-height: 1em;
                        cursor: pointer;
                        color: rgba(#fff, .7);
                        &:hover {
                                color: #fff;
                        }
                }
        }
        a {
                color: #666;
                &:hover {
                        color: #222;
                }
        }

        .footer-in {
                max-width: 1360px;
                padding: 40px 80px;
                margin: 0 auto;


                position: relative;
                display: flex;
                flex-wrap: wrap;
                .xss & {
                        padding: 40px;
                }
        }
        .column {
                flex: 1 0 150px;
                padding: 0 25px;
                text-align: left;
                border-left: 1px #eee solid;
                &:first-child {
                        border-left: none;
                }
                h3 {
                        font-family: $opensanscond;
                        font-weight: bold;
                        color: $yellow;
                        font-size: 16px;
                        text-transform: uppercase;
                        a {
                                color: $yellow;
                        }
                }
                a {display: block;margin: 12px 0;}
                .ul {
                        padding: 10px 0;
                }
        }
                
        .footer-about {
                color: #666;
                font-weight: 600;
                img {
                        width: 80%;
                }
                p {
                        margin: 20px 0;
                }
                strong {
                        font-weight: 700;
                }
        }
       .copyright {
                background: $gray;
                color: #fff;
                font-size: 10px;
                line-height: 1em;
                padding: 10px 0;
                margin: 0;
        }
}
        

.cookie-policy {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background: rgba(#fff, .9);
    color: #333;
    padding: 1em 64px 1em 1em;
    font-size: 0.9em;
    transition: all .3s ease-in-out;
    box-shadow: 0 0 4px rgba(#000, .3);
    &.closed {
        transform: translate(0, 100%);
    }

    .close {
        position: absolute;
        top: 50%;
        right: 32px;
        transform: translate(0, -50%);
        text-align: center;
        width: 32px;
        height: 32px;
        cursor: pointer;
        &:after {
            //content: $fa-var-close;
            //@include fa-icon;
        }
    }
}
