.field-recovery-services {
    position: relative;
    .bg {
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .block-in {
        position: relative;
        color: #fff;
        text-align: center;
    }

    h2.field.title {
        font-family: $opensanscond;
        font-size: var(--recovery-title);
        line-height: 1.4em;
        padding-top: 30px;
        text-align: center;
        margin: 0;
        font-weight: bold;
        color: #fff;
        text-shadow: 2px 2px 0 #333;
        text-transform: uppercase;
        display: inline-block;
        &:after {
            content: "";
            display: block;
            width: 70%;
            margin: .5em auto 1em;
            border-bottom: 3px solid #e4a327;
        }
    }

    .field.services {
        color: #f1f1f1;
        padding: 0 0 80px;
        text-shadow: 1px 1px 0 #333;
        text-align: left;
        ul {
            column-count: 3;
        }
        li {
            display: inline-block;
            padding: .8em 2em 1em 5em;
            margin: 20px 0;
            position: relative;
            text-align: left;
        }
        svg {
                //content: $fa-var-check-circle-o;
                //@include fa-icon;
                //font-size:3em;
                color: $orange;
                position: absolute;
                left: 0.5em;
                top: 0.8em;
                .sm & {
                    font-size:2em;
                }
            
        }       
    }
}
.xss,
.xs {
    .field-recovery-services {
        .field.services {
            ul {
                column-count: 1;
            }
        }
    }
}
.sm {
    .field-recovery-services {
        .field.services {
            padding: 0 40px 80px;
            ul {
                column-count: 2;
            }
        }
    }
}
