.dialog-handle svg {
    width: 24px;
    height: 24px;
    margin-left: 24px;
}
.form-language-selector {
    float: right;
    color: #fff;
    padding: 0px 24px;
    div, svg {
        color: #fff;
    }
}
div[role=tooltip] {
    z-index: 10000;
}
.downshift {
    display: flex;
    align-items: center;
    & > * {
        flex-grow: 0;
        &:first-child {
            flex-grow: 1;
        }
    }
}
.downshift-paper {
    max-height: 200px;
    overflow-y: auto;
}


#modal-container {
    .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 5000;
        /*
        &.full {
            .modalbg, .modal-content {
                position: fixed;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100vh;
                z-index: 0;
                //background: rgba(#000, .54);
            }
        }*/
        &.normal {
            .modalbg {
                position: fixed;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100vh;
                z-index: 0;
                background: rgba(#000, .54);
            }
            .modal-content {
                position: fixed;
                top: 50%;
                left: 50%;
                width: 80vw;
                height: 80vh;
                transform: translate3d(-50%, -50%, 0);
                background: #fff;
                border-radius: 8px;
                z-index: 1;
                transition: all .45s;
            }
            &.modal-appear,
            &.modal-enter {
                .modalbg {
                    opacity: 0;
                }
                .modal-content {
                    opacity: 0;
                    transform: translate3d(-50%, -50%, 0) scale(.2);
                }
                &.modal-appear-active,
                &.modal-enter-active {
                    .modalbg {
                        opacity: 1;
                        transition: all .45s;
                    }
                    .modal-content {
                        opacity: 1;
                        transform: translate3d(-50%, -50%, 0) scale(1);
                        transition: all .45s;
                    }
                }
            }
            &.modal-exit {
                .modalbg {
                    opacity: 1;
                }
                .modal-content {
                    opacity: 1;
                    transform: translate3d(-50%, -50%, 0) scale(1);
                }
                &.modal-exit-active {
                    .modalbg {
                        opacity: 0;
                        transition: all .45s;
                    }
                     .modal-content {
                        opacity: 0;
                        transform: translate3d(-50%, -50%, 0) scale(.2);
                        transition: all .45s;
                    }
                }
           }
        }
    }
}