.breadcrumb {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(#000, .5);
    color: #fff;
    font-size: var(--h1);
    line-height: 1.6em;
    padding: .4em 2em;
    margin: 0;
    p {margin:0;}
    a {
        color: #fff;
        &:hover {
            color: #fff;
        }
        &:not(:first-child):before {
            content: "»";
            display: inline-block;
            padding: 0 .5em;
        }
    }
}

