:root {
        --flota-text: 12px;
        --flota: 20px;
        --flota-label: 11px;
}
.sm {
        --flota-text: 16px;
        --flota: 24px;
        --flota-label: 14px;
}
.lg {
        --flota-text: 21px;
        --flota: 96px;
        --flota-label: 24px;
}
.xl {
        --flota-text: 21px;
        --flota: 96px;
        --flota-label: 24px;
}
div.field-flota-numbers.bg-prop {
    color: #fff;
    & > .content {
        display: flex;
        justify-content: stretch;
        align-items: stretch;
        .content-in {
            flex: 0 0 50%;
            padding: 80px;
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
        .counters {
            display: flex;
            justify-content: space-between;
            .fcounter {
                text-align: center;
            }
        }
    }
    
    h3 {
        font-size: var(--flota-text);
    }

    .align-justify {
        text-align: justify;
    }
    .counters {
        font-family: $opensanscond;
    }
    .counter {
        font-size: var(--flota);
        line-height: 1em;
        font-weight: bold;        
    }
    .label {
        font-size: var(--flota-label);
        text-transform: uppercase;
        font-weight: bold;        
    }
}