.field-editor {
    margin-bottom: 15px;
}
.field-editor-ref {
    position: relative;
}
.ref-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 1000;
}