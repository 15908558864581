:root {
        --slide-h1: 24px;
        --slide-h1-about-top: 50%;
        --slide-display-text: none;
        --text-big: 20px;
        --text-big-padding: 20px 0 10px;
        --slide-h1-big: 36px;
        --slide-h3-big: 20px;
        --action-size: 14px;
        --text-size: 18px;
        --icon-title-size: 16px;
        --card-title-size: 32px;
        --home-title-size: 22px;
}
.xss {
        --slide-h1: 30px;
        --slide-h1-big: 42px;
        --slide-h3-big: 24px;
        --card-title-size: 24px;
        --action-size: 14px;
    
}
.xs {
        --slide-h1: 30px;
        --slide-h1-big: 42px;
        --slide-h3-big: 24px;
        --card-title-size: 32px;
        --action-size: 14px;
}
.sm {
        --slide-h1: 42px;
        --text-big: 24px;
        --text-big-padding: 20px 0 10px;
        --slide-h1-big: 64px;
        --slide-h3-big: 32px;
        --action-size: 14px;
        --card-title-size: 32px;
        --home-title-size: 24px;
}
.md {
        --slide-h1: 42px;
        --slide-h1-about-top: 25%;
        --slide-display-text: block;
        --text-big: 24px;
        --text-big-padding: 40px 0 20px;
        --slide-h1-big: 64px;
        --slide-h3-big: 36px;
        --action-size: 17px;
        --icon-title-size: 22px;
        --card-title-size: 32px;
        --home-title-size: 28px;
}
.lg, .xl {
        --slide-h1: 48px;
        --slide-h1-about-top: 25%;
        --slide-display-text: block;
        --text-big: 26px;
        --text-big-padding: 64px 0 27px;
        --slide-h1-big: 96px;
        --slide-h3-big: 42px;
        --action-size: 18px;
        --text-size: 18px;
        --icon-title-size: 24px;
        --card-title-size: 42px;
        --home-title-size: 32px;
}
:root {
        --text-small: 11px;
        --recovery-h2: 16px;
        --recovery-title: 24px;
        --recovery-text: 11px;
        --recovery-small: 11px;
        --recovery-telno: 14px;
        --recovery-telno-big: 18px;
}
.xs {
        --recovery-h2: 18px;
        --recovery-text: 12px;
}
.sm {
        --text-small: 12px;
        --recovery-h2: 18px;
        --recovery-text: 12px;
}
.md {
        --text-small: 14px;
        --recovery-h2: 24px;
        --recovery-title: 40px;
        --recovery-text: 16px;
        --recovery-small: 14px;
        --recovery-telno: 24px;
        --recovery-telno-big: 28px;
}
.lg, .xl {
        --text-small: 16px;
        --recovery-h2: 24px;
        --recovery-title: 50px;
        --recovery-text: 20px;
        --recovery-small: 15px;
        --recovery-telno: 28px;
        --recovery-telno-big: 36px;
}


/*
xss: 0 - 400
xs:	400 - 600
sm: 600 - 900
md: 900 - 1200
lg: 1200 - 
*/
//$raleway: Raleway, sans-serif;
$opensans: 'Open Sans', sans-serif;
$opensanscond: 'Open Sans Condensed', sans-serif;
@font-face {
  font-family: 'MrDafoe2';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/MrDafoe-Regular2.ttf) format('truetype');
}
$dafoe: 'MrDafoe2', cursive;
 
.dafoe {
        font-family: $dafoe;
}
.logo-slogan-recuperam {
        background: url(/img/recuperam-slogan-big-d.png) center center no-repeat;
        background-size: contain;
}
$yellow: #F8B100;
$gray: #6f6f6f;
$green: #79bb7d;
$brown: #635c55;
$beige: #f7f6ef;
$orange: #e4a327;
 
$color-text: #444;
$color-link: #222;
$color-link-hover: #000; //darken($blue, 10%);
$color-button: $yellow;
$color-button-hover: lighten($yellow, 10%);
$color-scrollbar-thumb: rgba(#222, .5);
$color-scrollbar-thumb2: rgba(#222, .4);
$color-scrollbar-track: rgba(#222, .1);
$menu-color: #fff;
$menu-color-hover: $yellow;
$progress: $yellow;
$bg-action: $brown;

.white {
        color: #fff;
}
.yellow {
        color: $yellow;
}
